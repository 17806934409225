@tailwind base;
@tailwind components;
@tailwind utilities;

/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 100;
  src: url(../assets/fonts/Lato-Thin.ttf) format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: url(../assets/fonts/Lato-Light.ttf) format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Lato-Regular.ttf) format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/Lato-Bold.ttf) format("woff2");
}

/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: url(../assets/fonts/Lato-Black.ttf) format("woff2");
}

body {
  font-family: Lato;
}
